import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { CustomOfficeCard } from './styles';
const OfficeBalloon = ({ office, stateAppStore, }) => {
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const hasPrice = useMemo(() => (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) || (values === null || values === void 0 ? void 0 : values.hasTireStorage), [values === null || values === void 0 ? void 0 : values.tireOperationRefValueId, values === null || values === void 0 ? void 0 : values.hasTireStorage]);
    return (_jsx(CustomOfficeCard, { office: office, stateOrder: stateAppStore === null || stateAppStore === void 0 ? void 0 : stateAppStore.stateOrder, hasPrice: hasPrice }));
};
export default inject('stateAppStore')(observer(OfficeBalloon));
