import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import cc from 'classcat';
import { CloseIcon, ReturnIcon } from '..';
import { stateAppActions } from '../../../lib/mobx/stateAppStore';
const Header = (props) => {
    const { returnNumber, header, noNavigate, gradient, onCloseClick, noReturn, shadow = false, } = props;
    const handleClick = () => {
        if (onCloseClick) {
            onCloseClick();
        }
        if (returnNumber || returnNumber === 0) {
            stateAppActions.setAppStateOrder(returnNumber);
        }
    };
    return (_jsx("div", { className: cc(['header_widget', 'padding_main_layer', { shadow }]), children: _jsxs(Row, { children: [!noNavigate && !noReturn
                    && (_jsx(Col, { xs: 1, onClick: handleClick, children: _jsx("div", { className: 'pull-left header_button', children: _jsx(ReturnIcon, { onClick: handleClick }) }) })), _jsxs(Col, { xs: noReturn ? 12 : 11, className: `header_text${gradient ? ' avtoapp_color_font_20_gradient' : ' avtoapp_color_font_20'}`, children: [header, !noNavigate
                            && (_jsx("div", { className: 'pull-right header_button header_button_close', children: _jsx(Row, { onClick: handleClick, children: _jsx("div", { className: 'header_button_close_icon', children: _jsx(CloseIcon, { onClick: handleClick }) }) }) }))] })] }) }));
};
export default Header;
