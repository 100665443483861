import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// import Select from 'react-select';
// import Form from 'react-bootstrap/Form';
// import { useFormContext, Controller } from 'react-hook-form';
// import { Row, Col } from 'react-bootstrap';
import { useMediaQuery } from '@react-hooks-library/core';
import { SimpleSelect, SimpleSelectMobile, } from '..';
const Select = (props) => {
    const { label, options, required, error, controlId, name, disabled, placeholder, iconType, } = props;
    const isMobile = useMediaQuery('(max-width: 768px)');
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: isMobile
            ? (_jsx(SimpleSelectMobile, { controlId: controlId, label: label, error: error, required: required, options: options, name: name, iconType: iconType }))
            : (_jsx(SimpleSelect, { controlId: controlId, label: label, error: error, required: required, options: options, name: name, placeholder: placeholder, disabled: disabled })) }));
};
export default Select;
