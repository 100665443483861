import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useMediaQuery, useScroll } from '@react-hooks-library/core';
import { useFormContext } from 'react-hook-form';
const RightSidePanel = () => {
    const { setValue } = useFormContext(); // retrieve all hook methods
    const box = useRef(null);
    useScroll(box, ({ scrollY }) => { setValue('scrollY', scrollY); });
    const isMobile = useMediaQuery('(max-width: 768px)');
    return (_jsx("div", { children: isMobile ? null
            : (_jsx("div", { className: 'right-side-panel', children: _jsx("a", { href: 'https://avtoapp.ru/legal/rulesofuse.html', children: "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u0435" }) })) }));
};
export default inject('stateAppStore')(observer(RightSidePanel));
