var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// дизайн
// https://www.figma.com/file/za6K0CTN0N55EarAM9kYnj/%D0%A1%D0%B0%D0%B9%D1%82?type=design&node-id=529-3189&mode=design&t=fWMAr7lp17xA1rVM-0
import { Row, Col } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { useMediaQuery } from '@react-hooks-library/core';
import { referenceActions } from './store/referenceStore';
import { clientPromoActions } from './store/clientPromoStore';
import { officeActions } from '../map/store/officeStore';
import { Header, Footer, PriceFrom } from '../core';
import { useScrollWithShadow } from '../core/hooks/useScrollWithShadow';
import PromoView from '../core/visualComponents/PromoView';
import NoPromoMessage from './NoPromoMessage';
const PromoSelectPanel = (props) => {
    var _a, _b, _c;
    const { referenceStore, officeStore, clientPromoStore } = props;
    const ref56 = (_a = referenceStore === null || referenceStore === void 0 ? void 0 : referenceStore.ref56) !== null && _a !== void 0 ? _a : [];
    const { officesRequest, officesRequestByParams } = officeStore;
    const { clientPromos68, clientPromos69 } = clientPromoStore;
    const { scroller, positions } = useScrollWithShadow(ref56.length);
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const [showRequired, setShowRequired] = useState(false);
    const hasTireStorage = (_b = values.hasTireStorage) !== null && _b !== void 0 ? _b : false;
    const tireOperationRefValueId = (_c = values.tireOperationRefValueId) !== null && _c !== void 0 ? _c : null;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const loadOffices = () => __awaiter(void 0, void 0, void 0, function* () {
        const isEqualTireOperationArray = !officesRequest
            || isEqual(officesRequest.tireOperationRefValueId, tireOperationRefValueId ? [tireOperationRefValueId] : []);
        if (!officesRequest
            || officesRequest.hasTireStorage !== hasTireStorage
            || officesRequest.showPhotoList !== false
            || !isEqualTireOperationArray
            || !!officesRequestByParams // обновим список, если до этого офисы выбрали по officesRequestByParams
        ) {
            yield officeActions.getOffices({
                tireOperationRefValueId: tireOperationRefValueId
                    ? [tireOperationRefValueId]
                    : [],
                hasTireStorage,
                showPhotoList: true,
            });
        }
    });
    const loadClienPromos = () => __awaiter(void 0, void 0, void 0, function* () {
        yield clientPromoActions.getClientPromos68({
            serviceId: 68,
            // shortKey: 'rt4t6y',
            shortKey: values === null || values === void 0 ? void 0 : values.shortKey,
        });
        yield clientPromoActions.getClientPromos69({
            serviceId: 69,
            shortKey: values === null || values === void 0 ? void 0 : values.shortKey,
            // shortKey: 'rt4t6y',
        });
    });
    useEffect(() => {
        loadClienPromos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        loadOffices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTireStorage, tireOperationRefValueId]);
    useEffect(() => {
        var _a;
        const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
            yield referenceActions.getRef56();
        });
        loadData();
        // Блок получение данных с везём колёса:
        if (sessionStorage.getItem('tireOperationRefValueId')) {
            setValue('tireOperationRefValueId', parseInt((_a = sessionStorage.getItem('tireOperationRefValueId')) !== null && _a !== void 0 ? _a : '244', 10));
        }
        // Конец блок получение данных с везём колёса:
    }, []);
    const setServiceId = useCallback(() => {
        if (values === null || values === void 0 ? void 0 : values.clientPromo68) {
            setValue('serviceId', 68);
        }
        else if (values === null || values === void 0 ? void 0 : values.clientPromo69) {
            setValue('serviceId', 69);
        }
        else {
            setValue('serviceId', null);
        }
    }, [values === null || values === void 0 ? void 0 : values.clientPromo68, values === null || values === void 0 ? void 0 : values.clientPromo69, setValue]);
    useEffect(() => {
        setShowRequired(false);
        setServiceId();
    }, [tireOperationRefValueId, setServiceId]);
    useEffect(() => {
        setServiceId();
    }, [hasTireStorage, setServiceId]);
    const validation = () => {
        if (!(values === null || values === void 0 ? void 0 : values.clientPromo68) && !(values === null || values === void 0 ? void 0 : values.clientPromo69)) {
            setShowRequired(true);
            return false;
        }
        return true;
    };
    /* const handleChange = (v) => {
      setValue('tireOperationRefValueId', values?.tireOperationRefValueId === v.id ? null : v.id);
      setValue('tireOperationRefValueName', values?.tireOperationRefValueId === v.id ? null : v.name);
      setValue('isExactPrice', exactPrice.includes(v.id));
      if (v.id === 487) { setValue('tireRunflatOptionValue', 'yes'); } else { setValue('tireRunflatOptionValue', 'no'); }
    }; */
    const params = new URLSearchParams(window.location.search);
    return (_jsxs(_Fragment, { children: [(clientPromos69.length > 0 || clientPromos68.length > 0) && (_jsx(Header, { returnNumber: 0, header: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0443\u0441\u043B\u0443\u0433\u0438', noNavigate: !isMobile || !!values.minimized, noReturn: true, gradient: true, onCloseClick: () => {
                    setValue('minimized', !values.minimized);
                }, shadow: positions.top })), !values.minimized && (_jsxs("div", { className: `padding_main_layer ${clientPromos69.length > 0 || clientPromos68.length > 0 ? ' scroll_wrapper' : ''}`, ref: scroller, children: [clientPromos69.length === 0 && clientPromos68.length === 0 && (_jsx(NoPromoMessage, {})), clientPromos69.length > 0 && (_jsxs(Row, { className: 'tire_operation_list', children: [_jsx("div", { className: 'label pt-1', children: "\u0423\u0441\u043B\u0443\u0433\u0438 \u043F\u043E \u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044E \u0448\u0438\u043D" }), _jsx("div", { className: 'padding_main_layer office-selector scroll_wrapper large_footer', ref: scroller, children: _jsx(Row, { className: 'pb-3', children: _jsx(Col, { children: clientPromos69.map((v) => {
                                            var _a, _b;
                                            return (_jsx("div", { children: _jsx(PromoView, { clientPromo: v, isSelectable: true, selected: ((_a = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _a === void 0 ? void 0 : _a.clientPromoId)
                                                        === v.clientPromoId, onClick: () => {
                                                        var _a, _b, _c, _d, _e, _f;
                                                        if (v.clientPromoId
                                                            === ((_a = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _a === void 0 ? void 0 : _a.clientPromoId)) {
                                                            setValue('clientPromo69', null);
                                                            setValue('diameterWheelRefValueId', (_c = (_b = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _b === void 0 ? void 0 : _b.diameterWheelRefValueId) !== null && _c !== void 0 ? _c : 67);
                                                        }
                                                        else {
                                                            setValue('clientPromo69', v);
                                                            // Обрати внимание на порядок полей: values?.diameterWheelRefValueId ??  v.diameterWheelRefValueId
                                                            // сравни с установкой clientPromo68
                                                            // это сделано, т.к. в приоритете диаметр, указанный промо акции по шиномонтажу
                                                            setValue('diameterWheelRefValueId', (_f = (_e = (_d = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _d === void 0 ? void 0 : _d.diameterWheelRefValueId) !== null && _e !== void 0 ? _e : v.diameterWheelRefValueId) !== null && _f !== void 0 ? _f : 67);
                                                        }
                                                    }, 
                                                    // showPrice={ values?.partnerOfficeId === v.id }
                                                    showBorderSelected: ((_b = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _b === void 0 ? void 0 : _b.clientPromoId)
                                                        === v.clientPromoId }) }, v.clientPromoId));
                                        }) }) }) })] })), clientPromos69.length > 0 && clientPromos68.length > 0 && (_jsx(Row, { className: 'delimiter', children: _jsx("div", {}) })), clientPromos68.length > 0 && (_jsxs(Row, { className: 'tire_operation_list', children: [_jsx("div", { className: 'label pt-1', children: "\u0423\u0441\u043B\u0443\u0433\u0438 \u0448\u0438\u043D\u043E\u043C\u043E\u043D\u0442\u0430\u0436\u0430" }), _jsx("div", { className: 'padding_main_layer office-selector scroll_wrapper large_footer', ref: scroller, children: _jsx(Row, { className: 'pb-3', children: _jsx(Col, { children: clientPromos68.map((v) => {
                                            var _a, _b;
                                            return (_jsx("div", { children: _jsx(PromoView, { clientPromo: v, isSelectable: true, selected: ((_a = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _a === void 0 ? void 0 : _a.clientPromoId)
                                                        === v.clientPromoId, onClick: () => {
                                                        var _a, _b, _c, _d, _e, _f;
                                                        if (v.clientPromoId
                                                            === ((_a = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _a === void 0 ? void 0 : _a.clientPromoId)) {
                                                            setValue('clientPromo68', null);
                                                            setValue('diameterWheelRefValueId', (_c = (_b = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _b === void 0 ? void 0 : _b.diameterWheelRefValueId) !== null && _c !== void 0 ? _c : 67);
                                                        }
                                                        else {
                                                            setValue('clientPromo68', v);
                                                            // Обрати внимание на порядок полей: v.diameterWheelRefValueId ?? values?.diameterWheelRefValueId
                                                            // сравни с установкой clientPromo69
                                                            // это сделано, т.к. в приоритете диаметр, указанный промо акции по шиномонтажу
                                                            setValue('diameterWheelRefValueId', (_f = (_d = v.diameterWheelRefValueId) !== null && _d !== void 0 ? _d : (_e = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _e === void 0 ? void 0 : _e.diameterWheelRefValueId) !== null && _f !== void 0 ? _f : 67);
                                                        }
                                                    }, 
                                                    // showPrice={ values?.partnerOfficeId === v.id }
                                                    showBorderSelected: ((_b = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _b === void 0 ? void 0 : _b.clientPromoId)
                                                        === v.clientPromoId }) }, v.clientPromoId));
                                        }) }) }) })] })), showRequired && (_jsx(Row, { className: 'pt-2', children: _jsx(Col, { className: 'font-required_red_16', children: "\u0427\u0442\u043E\u0431\u044B \u043F\u0440\u043E\u0439\u0442\u0438 \u0434\u0430\u043B\u044C\u0448\u0435, \u0441\u043D\u0430\u0447\u0430\u043B\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0443\u0441\u043B\u0443\u0433\u0443" }) })), !!params.get('debug') && (_jsxs(_Fragment, { children: [officeStore.offices && officeStore.offices.length > 0 && (_jsx(Row, { children: _jsx(Col, { children: _jsx(PriceFrom, { office: officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices[0] }) }) })), _jsx(Row, { children: _jsx(Col, { children: officeStore === null || officeStore === void 0 ? void 0 : officeStore.offices.map((v) => {
                                        var _a, _b, _c, _d;
                                        return (_jsx("li", { children: `${v.name}- Бонус(%) 68-${(_b = (_a = v === null || v === void 0 ? void 0 : v.bonusReceivingPercentByService) === null || _a === void 0 ? void 0 : _a.stationTireFitting) !== null && _b !== void 0 ? _b : ' Нет'}  69-${(_d = (_c = v === null || v === void 0 ? void 0 : v.bonusReceivingPercentByService) === null || _c === void 0 ? void 0 : _c.stationTireStorage) !== null && _d !== void 0 ? _d : ' Нет'}` }, v.id));
                                    }) }) })] }))] })), (clientPromos69.length > 0 || clientPromos68.length > 0) && (_jsx(Footer, { label: !values.minimized ? 'Далее' : 'Выбрать услуги', nextNumber: !values.minimized ? 1 : null, validation: validation, onClick: () => {
                    setValue('minimized', false);
                }, shadow: positions.bottom })), clientPromos69.length === 0 && clientPromos68.length === 0 && (_jsx(Footer, { label: '\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u043C\u0430\u0433\u0430\u0437\u0438\u043D', 
                // nextNumber={ !values.minimized ? 1 : null }
                // validation={ validation }
                onClick: () => {
                    window.location.href = 'https://vezemkolesa.ru/';
                }, shadow: positions.bottom }))] }));
};
export default inject('referenceStore', 'officeStore', 'clientPromoStore')(observer(PromoSelectPanel));
