var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { AsYouType } from 'libphonenumber-js';
import { useInterval, useMediaQuery } from '@react-hooks-library/core';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { toJS } from 'mobx';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { SmsInput } from '../core';
import { authActions } from './store/authStore';
import { schedulerActions } from './store/schedulerStore';
const SmsPanelContent = (props) => {
    var _a;
    const { schedulerStore } = props;
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const params = new URLSearchParams(window.location.search);
    const [countSeconds, setCountSeconds] = useState(60);
    const [confirmMessageError, setConfirmMessageError] = useState(null);
    const [token, setToken] = useState('');
    const [visible, setVisible] = useState(false);
    const hostname = ((_a = window.location.hostname) !== null && _a !== void 0 ? _a : '').trim();
    const repeatSms = useCallback(() => setCountSeconds((prev) => prev - 1), []);
    useInterval(repeatSms, 1000);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const translateError = (s) => {
        if (s === 'Wrong login or password') {
            return 'Неправильный код';
        }
        if (s === 'Password expired') {
            return 'Неправильный код';
        }
        return s;
    };
    /* const {
      tireFittingPriceResponse,
      tireStoragePriceResponse,
    } = calculatePriceStore; */
    const handleClose = () => {
        setValue('smsCode', null);
        stateAppActions.setAppStateOrder(3);
    };
    /* const handleСhangePhone = () => {
      setValue('smsCode', null);
      stateAppActions.setAppStateOrder(6);
    }; */
    const handleChallengeHidden = useCallback(() => setVisible(false), []);
    const sendSMS = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setValue('smsAlreadySend', true);
        setCountSeconds(60);
        setValue('smsAlreadySend', true);
        if ((!params.get('nosms')) && ((values === null || values === void 0 ? void 0 : values.captchaToken) || token)) {
            yield authActions.sendSms({ phoneNumber: values.phoneNumber, fcmToken: 'empty', captchaToken: (_b = values === null || values === void 0 ? void 0 : values.captchaToken) !== null && _b !== void 0 ? _b : token });
            setValue('captchaToken', null);
            setToken(null);
            setVisible(false);
        }
    });
    const tireComlect = (promo) => {
        var _a, _b, _c, _d, _e;
        return `${(_a = promo === null || promo === void 0 ? void 0 : promo.tireBrand) !== null && _a !== void 0 ? _a : ''} ${(_b = promo === null || promo === void 0 ? void 0 : promo.tireModel) !== null && _b !== void 0 ? _b : ''} ${(_c = promo === null || promo === void 0 ? void 0 : promo.tireWidth) !== null && _c !== void 0 ? _c : ''}/${(_d = promo === null || promo === void 0 ? void 0 : promo.tireHeight) !== null && _d !== void 0 ? _d : ''} ${(_e = promo === null || promo === void 0 ? void 0 : promo.diameterWheelRefValueName) !== null && _e !== void 0 ? _e : ''}`;
    };
    const putAppointment = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
        let appointmentResponse = null;
        // clientActions.updateClient({ firstName: values?.firstName, lastName: values?.lastName }, values?.token);
        if (values === null || values === void 0 ? void 0 : values.appointmentJournalId) {
            if ((values === null || values === void 0 ? void 0 : values.clientPromo68) && (!(values === null || values === void 0 ? void 0 : values.clientPromo69))) {
                appointmentResponse = yield schedulerActions.putAppointment({
                    vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
                    diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
                    tireOperationRefValueId: values === null || values === void 0 ? void 0 : values.tireOperationRefValueId,
                    tireRunflat: (values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'yes',
                    isStorageRequired: false,
                    // returningWheelsKitOrderId?: string,
                    wheelBalancing: false,
                    vehicleNumber: values === null || values === void 0 ? void 0 : values.vehicleNumber,
                    isForeign: values === null || values === void 0 ? void 0 : values.isForeign,
                    service68: {
                        cost: (_d = (_c = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _c === void 0 ? void 0 : _c.price) !== null && _d !== void 0 ? _d : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCount,
                        clientPromoId: values === null || values === void 0 ? void 0 : values.clientPromo68.clientPromoId,
                    },
                    // storageActionRefValueId?: 445,
                    // storageDurationInMonths?: values?.storageDurationInMonths,
                    // storedObjectRefValueId?:  values?.storedObjectRefValueId,
                    utmCampaign: (_e = values === null || values === void 0 ? void 0 : values.utmCampaign) !== null && _e !== void 0 ? _e : Cookies.get('avtoapp_utm_term'),
                    utmContent: (_f = values === null || values === void 0 ? void 0 : values.utmContent) !== null && _f !== void 0 ? _f : Cookies.get('avtoapp_utm_content'),
                    utmMedium: (_g = values === null || values === void 0 ? void 0 : values.utmMedium) !== null && _g !== void 0 ? _g : Cookies.get('avtoapp_utm_medium'),
                    utmSource: (_h = values === null || values === void 0 ? void 0 : values.utmSource) !== null && _h !== void 0 ? _h : Cookies.get('avtoapp_utm_source'),
                    utmTerm: (_j = values === null || values === void 0 ? void 0 : values.utmTerm) !== null && _j !== void 0 ? _j : Cookies.get('avtoapp_utm_term'),
                    sourceRefValueId: 470, // сайт
                    sourceDomain: hostname,
                    comment: `Промо акция: ${values === null || values === void 0 ? void 0 : values.clientPromo68.name}, заказ ${values === null || values === void 0 ? void 0 : values.clientPromo68.externalOrderNumber}, ${tireComlect(values === null || values === void 0 ? void 0 : values.clientPromo68)}, `,
                }, values === null || values === void 0 ? void 0 : values.appointmentJournalId, values === null || values === void 0 ? void 0 : values.token);
            }
            if ((values === null || values === void 0 ? void 0 : values.clientPromo68) && (values === null || values === void 0 ? void 0 : values.clientPromo69)) {
                appointmentResponse = yield schedulerActions.putAppointment({
                    vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
                    diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
                    tireOperationRefValueId: values === null || values === void 0 ? void 0 : values.tireOperationRefValueId,
                    tireRunflat: (values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'yes',
                    isStorageRequired: true,
                    // returningWheelsKitOrderId?: string,
                    wheelBalancing: false,
                    vehicleNumber: values === null || values === void 0 ? void 0 : values.vehicleNumber,
                    isForeign: values === null || values === void 0 ? void 0 : values.isForeign,
                    service68: {
                        cost: (_l = (_k = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _k === void 0 ? void 0 : _k.price) !== null && _l !== void 0 ? _l : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCount,
                        clientPromoId: values === null || values === void 0 ? void 0 : values.clientPromo68.clientPromoId,
                    },
                    service69: {
                        cost: (_o = (_m = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _m === void 0 ? void 0 : _m.price) !== null && _o !== void 0 ? _o : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCountStorage,
                        clientPromoId: values === null || values === void 0 ? void 0 : values.clientPromo69.clientPromoId,
                    },
                    storageActionRefValueId: 445,
                    storageDurationInMonths: values === null || values === void 0 ? void 0 : values.storageDurationInMonths,
                    storedObjectRefValueId: values === null || values === void 0 ? void 0 : values.storedObjectRefValueId,
                    utmCampaign: (_p = values === null || values === void 0 ? void 0 : values.utmCampaign) !== null && _p !== void 0 ? _p : Cookies.get('avtoapp_utm_term'),
                    utmContent: (_q = values === null || values === void 0 ? void 0 : values.utmContent) !== null && _q !== void 0 ? _q : Cookies.get('avtoapp_utm_content'),
                    utmMedium: (_r = values === null || values === void 0 ? void 0 : values.utmMedium) !== null && _r !== void 0 ? _r : Cookies.get('avtoapp_utm_medium'),
                    utmSource: (_s = values === null || values === void 0 ? void 0 : values.utmSource) !== null && _s !== void 0 ? _s : Cookies.get('avtoapp_utm_source'),
                    utmTerm: (_t = values === null || values === void 0 ? void 0 : values.utmTerm) !== null && _t !== void 0 ? _t : Cookies.get('avtoapp_utm_term'),
                    comment: `Промо акции: ${values === null || values === void 0 ? void 0 : values.clientPromo68.name}, заказ ${values === null || values === void 0 ? void 0 : values.clientPromo68.externalOrderNumber}, ${tireComlect(values === null || values === void 0 ? void 0 : values.clientPromo68)}; ${values === null || values === void 0 ? void 0 : values.clientPromo69.name}, заказ ${values === null || values === void 0 ? void 0 : values.clientPromo69.externalOrderNumber}, ${tireComlect(values === null || values === void 0 ? void 0 : values.clientPromo68)}`,
                }, values === null || values === void 0 ? void 0 : values.appointmentJournalId, values === null || values === void 0 ? void 0 : values.token);
            }
            if ((!(values === null || values === void 0 ? void 0 : values.clientPromo68)) && (values === null || values === void 0 ? void 0 : values.clientPromo69)) {
                appointmentResponse = yield schedulerActions.putAppointment({
                    vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
                    diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
                    // tireOperationRefValueId: values?.tireOperationRefValueId,
                    // tireRunflat: false,
                    // isStorageRequired: false,
                    // returningWheelsKitOrderId?: string,
                    // wheelBalancing: false,
                    /* service68: {
                      cost: tireFittingPriceResponse?.price ?? 0,
                      tireCount: values?.tireCount,
                    }, */
                    service69: {
                        cost: (_v = (_u = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _u === void 0 ? void 0 : _u.price) !== null && _v !== void 0 ? _v : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCountStorage,
                        clientPromoId: values === null || values === void 0 ? void 0 : values.clientPromo69.clientPromoId,
                    },
                    storageActionRefValueId: 445,
                    storageDurationInMonths: values === null || values === void 0 ? void 0 : values.storageDurationInMonths,
                    storedObjectRefValueId: values === null || values === void 0 ? void 0 : values.storedObjectRefValueId,
                    utmCampaign: (_w = values === null || values === void 0 ? void 0 : values.utmCampaign) !== null && _w !== void 0 ? _w : Cookies.get('avtoapp_utm_term'),
                    utmContent: (_x = values === null || values === void 0 ? void 0 : values.utmContent) !== null && _x !== void 0 ? _x : Cookies.get('avtoapp_utm_content'),
                    utmMedium: (_y = values === null || values === void 0 ? void 0 : values.utmMedium) !== null && _y !== void 0 ? _y : Cookies.get('avtoapp_utm_medium'),
                    utmSource: (_z = values === null || values === void 0 ? void 0 : values.utmSource) !== null && _z !== void 0 ? _z : Cookies.get('avtoapp_utm_source'),
                    utmTerm: (_0 = values === null || values === void 0 ? void 0 : values.utmTerm) !== null && _0 !== void 0 ? _0 : Cookies.get('avtoapp_utm_term'),
                    comment: `Промо акция: ${values === null || values === void 0 ? void 0 : values.clientPromo69.name}, заказ ${values === null || values === void 0 ? void 0 : values.clientPromo69.externalOrderNumber}, ${tireComlect(values === null || values === void 0 ? void 0 : values.clientPromo68)}, `,
                }, values === null || values === void 0 ? void 0 : values.appointmentJournalId, values === null || values === void 0 ? void 0 : values.token);
            }
            // Если в ответе есть appointmentResponse.id, то это успешный успех (но это не точно)
            if (appointmentResponse.id) {
                stateAppActions.setAppStateOrder(7);
            }
        }
    });
    const errorMessage = toJS(schedulerStore.error);
    const formatPhone = () => {
        var _a;
        const asYouType = new AsYouType('RU');
        asYouType.input((_a = values === null || values === void 0 ? void 0 : values.phoneNumber) !== null && _a !== void 0 ? _a : '');
        return asYouType.getNumber().formatInternational();
    };
    const confirmSMS = () => __awaiter(void 0, void 0, void 0, function* () {
        var _1, _2;
        const confirmSms = yield authActions.confirmSms({
            phoneNumber: values.phoneNumber,
            password: values === null || values === void 0 ? void 0 : values.smsCode,
        });
        setValue('token', (_1 = confirmSms.token) !== null && _1 !== void 0 ? _1 : null);
        setConfirmMessageError((_2 = confirmSms.message) !== null && _2 !== void 0 ? _2 : null);
        // setCountSecondsError(7);
    });
    useEffect(() => {
        if ((values === null || values === void 0 ? void 0 : values.appointmentJournalId) && (values === null || values === void 0 ? void 0 : values.token)) {
            putAppointment();
        }
    }, [values === null || values === void 0 ? void 0 : values.token]);
    useEffect(() => {
        if ((values === null || values === void 0 ? void 0 : values.smsCode) && ((values === null || values === void 0 ? void 0 : values.smsCode.length) === 4)) {
            confirmSMS();
        }
    }, [values === null || values === void 0 ? void 0 : values.smsCode]);
    useEffect(() => {
        // TODO -  if (!values?.token) { - для отладки добавил, вырезать
        // данная проверка позволяет оформить повторный заказ без ввода СМС. Верно или нет ? Пока не понятно.
        if (!(values === null || values === void 0 ? void 0 : values.token) && !(values === null || values === void 0 ? void 0 : values.smsAlreadySend)) {
            setVisible(true);
        }
    }, []);
    useEffect(() => {
        if (token) {
            setValue('captchaToken', token);
            sendSMS();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    return (_jsxs("div", { className: 'modal_auth_form', children: [!isMobile && (_jsx(Row, { children: _jsxs(Col, { className: 'avtoapp_color_font_20', children: ["\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0437\u0430\u043F\u0438\u0441\u0438", _jsx(FontAwesomeIcon, { icon: faXmark, className: 'pull-right font-20-500-middle-gray-dark header_button', onClick: handleClose })] }) })), _jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'font-14-400-black-noSpacing', children: "\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u043A\u043E\u0434 \u043D\u0430 \u0432\u0430\u0448 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430:" }) }), _jsx(Row, { className: 'phone_line', children: _jsx(Col, { className: 'phone_direction', children: _jsx("div", { className: 'font-14-400', children: formatPhone() }) }) }), (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.errorCode) === 'IMPOSSIBLE_ACTION'
                && (_jsxs(Row, { className: 'pt-4', children: [_jsx(Col, { md: 7, className: 'error-container pt-2', children: _jsx("div", { children: "\u0418\u0437\u0432\u0438\u043D\u0438\u0442\u0435, \u0432\u044B \u043D\u0435 \u043C\u043E\u0436\u0435\u0442\u0435 \u0437\u0430\u043F\u0438\u0441\u0430\u0442\u044C\u0441\u044F \u043D\u0430 \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u0443\u044E \u0434\u0430\u0442\u0443. \u0412\u044B\u0431\u0435\u0440\u0435\u0442\u0435 \u0434\u0440\u0443\u0433\u0443\u044E \u0434\u0430\u0442\u0443." }) }), _jsx(Col, { md: 5, children: _jsx(Button, { className: 'simple-button', variant: 'primary', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    schedulerActions.clearError();
                                    stateAppActions.setAppStateOrder(3);
                                    setValue('date', null);
                                    setValue('time', null);
                                }), children: "\u0412\u044B\u0431\u043E\u0440 \u0434\u0430\u0442\u044B" }) })] })), (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.errorCode) !== 'IMPOSSIBLE_ACTION' && (_jsx(Row, { children: _jsxs("div", { className: (countSeconds <= 0) ? 'sms_line sms_line_reverse' : 'sms_line', children: [_jsx("div", { children: _jsx(SmsInput, { label: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u043E\u0434', name: 'smsCode', error: translateError(confirmMessageError) }) }), _jsxs("div", { className: 'repeat', children: [(countSeconds > 0)
                                    && (_jsxs(_Fragment, { children: [_jsx("div", { className: 'label', children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E" }), _jsxs("div", { className: 'seconds', children: [countSeconds, " \u0441\u0435\u043A"] })] })), (countSeconds <= 0)
                                    && (_jsx(Button, { className: 'simple-button', variant: 'primary', onClick: () => {
                                            if (token) {
                                                sendSMS();
                                            }
                                            else {
                                                setVisible(true);
                                            }
                                        }, children: "\u041E\u0422\u041F\u0420\u0410\u0412\u0418\u0422\u042C \u0421\u041C\u0421" }))] })] }) })), visible
                && (_jsx(Row, { children: _jsx(InvisibleSmartCaptcha, { sitekey: process.env.YANDEX_CAPTCHA_KEY, onSuccess: setToken, onChallengeHidden: handleChallengeHidden, visible: visible }) }))] }));
};
export default inject('calculatePriceStore', 'schedulerStore')(observer(SmsPanelContent));
