import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { RadioSelected, RadioUnselected } from '..';
const PromoView = (props) => {
    const { showBorder, showBorderSelected, 
    // showPrice,
    isSelectable, selected, onClick, clientPromo,
    // showPhotoPreview,
     } = props;
    const tireComlect = (promo) => {
        var _a, _b, _c, _d, _e;
        return `${(_a = promo === null || promo === void 0 ? void 0 : promo.tireBrand) !== null && _a !== void 0 ? _a : ''} ${(_b = promo === null || promo === void 0 ? void 0 : promo.tireModel) !== null && _b !== void 0 ? _b : ''} ${(_c = promo === null || promo === void 0 ? void 0 : promo.tireWidth) !== null && _c !== void 0 ? _c : ''}/${(_d = promo === null || promo === void 0 ? void 0 : promo.tireHeight) !== null && _d !== void 0 ? _d : ''} ${(_e = promo === null || promo === void 0 ? void 0 : promo.diameterWheelRefValueName) !== null && _e !== void 0 ? _e : ''}`;
    };
    const promoInternalView = () => {
        var _a;
        return (_jsxs("div", { className: 'officeView', children: [isSelectable
                    && (_jsx("div", { className: 'selectSvg', children: selected ? _jsx(RadioSelected, {}) : _jsx(RadioUnselected, {}) })), _jsx("div", { className: 'rightDiv', children: _jsxs(Row, { children: [_jsx(Col, { md: 12, className: 'avtoapp_color_font_16_18-75', children: clientPromo === null || clientPromo === void 0 ? void 0 : clientPromo.name }), process.env.DEBUG
                                && (_jsx(Col, { md: 12, className: 'font-14-400-gray-noSpacing', children: (_a = clientPromo === null || clientPromo === void 0 ? void 0 : clientPromo.externalOrderNumber) !== null && _a !== void 0 ? _a : 'Заказ из 1С' })), _jsx(Col, { md: 12, className: 'font-14-400-gray-noSpacing', children: tireComlect(clientPromo) }), _jsx(Col, { md: 12, className: !(clientPromo === null || clientPromo === void 0 ? void 0 : clientPromo.price) ? 'font-14-500-green-noSpacing' : 'font-14-500-black-noSpacing', children: (clientPromo === null || clientPromo === void 0 ? void 0 : clientPromo.price) ? `${clientPromo === null || clientPromo === void 0 ? void 0 : clientPromo.price}  ₽` : 'Бесплатно' })] }) })] }));
    };
    return (_jsx("div", { children: (showBorder || showBorderSelected)
            ? (_jsx(Row, { className: 'pt-3', onClick: () => { if (onClick) {
                    onClick();
                } }, children: _jsx(Col, { children: _jsx("div", { className: showBorderSelected ? 'bordered_block_selected' : 'bordered_block', children: promoInternalView() }) }) }))
            : (_jsx(Row, { className: 'pt-3', onClick: () => { if (onClick) {
                    onClick();
                } }, children: promoInternalView() })) }));
};
export default PromoView;
