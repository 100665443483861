import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
import InputGroup from 'react-bootstrap/InputGroup';
import React from 'react';
import { iconSelector } from '../icons/iconSelector';
import { validateField } from './validation';
const SimpleInput = (props, ref) => {
    const { label, required, controlId, name, iconType, validationSchema, shouldValidate, className, error, disabled, autoFocus, // mRef,
     } = props;
    const { control, getValues } = useFormContext();
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema) ? validateField(name, getValues(), validationSchema) : null);
    const inputGroupComponent = (field) => {
        return (_jsx(InputGroup, { children: _jsx(Form.Control, Object.assign({}, field, { placeholder: label, 
                // value={ options.find(v => v.value === field.value) }
                onChange: (e) => {
                    field.onChange(e.target.value);
                }, className: `simple-edit ${errorLocal ? 'error-component error-border error-color-font' : ''}`, disabled: disabled, autoFocus: autoFocus, ref: ref })) }));
    };
    return (_jsxs(Form.Group, { controlId: controlId, className: `componentRow ${className}`, children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                    if (iconType) {
                        return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                    }
                    return inputGroupComponent(field);
                } }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: errorLocal !== null && errorLocal !== void 0 ? errorLocal : ' ' }) })] }));
};
export default inject('referenceStore')(observer(React.forwardRef(SimpleInput)));
