import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from 'react-bootstrap/Container';
import { inject, observer } from 'mobx-react';
// import { useScroll } from '@react-hooks-library/core';
// import { useFormContext } from 'react-hook-form';
// import { StateAppStore } from '../../lib/mobx/stateAppStore';
import { Logo, Avtoapp } from '../core';
/* export interface MenuPanelProps {
  stateAppStore?: StateAppStore;
}*/
const MenuPanel = () => {
    // const stateOrder = stateAppStore?.stateOrder ?? 0;
    // const { setValue } = useFormContext(); // retrieve all hook methods
    // const box = useRef<HTMLDivElement | null>(null);
    // useScroll(box, ({ scrollY }) => { setValue('scrollY', scrollY); });
    return (_jsx("div", { className: 'menu-box', children: _jsx(Container, { fluid: true, children: _jsxs("div", { className: 'menu-layer', children: [_jsx("div", { className: 'd-flex align-items-center mobile-hidden', children: _jsx("div", { className: 'avtoapp', children: _jsx(Avtoapp, {}) }) }), _jsx("div", { className: 'd-flex align-items-center', children: _jsx("div", { className: 'logo', children: _jsx(Logo, {}) }) }), _jsxs("div", { className: 'd-flex align-items-center header-info', children: ["\u0428\u0438\u043D\u043E\u043C\u043E\u043D\u0442\u0430\u0436 ", _jsx("br", {}), " \u0438 \u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D"] }), _jsx("div", { className: 'd-flex align-items-center header-mobile-actions' })] }) }) }));
};
export default inject('stateAppStore')(observer(MenuPanel));
