import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from '@react-hooks-library/core';
import { Header } from '../core';
import PhonePanelContent from './PhonePanelContent';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
const PhoneModalPanel = () => {
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const [oldPhone] = useState(null);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleClose = () => {
        setValue('phoneNumber', oldPhone);
        stateAppActions.setAppStateOrder(5);
    };
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: isMobile ? (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 5, header: '\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430', noNavigate: !isMobile, gradient: true, onCloseClick: () => { setValue('minimized', !values.minimized); } }), _jsx(PhonePanelContent, {})] })) : (_jsx(Modal, { show: true, onHide: handleClose, backdrop: 'static', children: _jsx(PhonePanelContent, {}) })) }));
};
export default PhoneModalPanel;
