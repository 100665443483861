import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from '@react-hooks-library/core';
import { Header } from '../core';
import SmsPanelContent from './SmsPanelContent';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
const SmsModalPanel = () => {
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleClose = () => { stateAppActions.setAppStateOrder(3); };
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: isMobile ? (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 3, header: '\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0437\u0430\u043F\u0438\u0441\u0438', noNavigate: !isMobile, gradient: true, onCloseClick: () => { setValue('minimized', !values.minimized); } }), _jsx(SmsPanelContent, {})] })) : (_jsx(Modal, { show: true, onHide: handleClose, backdrop: 'static', "aria-labelledby": 'form-modal', children: _jsx(SmsPanelContent, {}) })) }));
};
export default SmsModalPanel;
