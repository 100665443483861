import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
// get our fontawesome imports
import { faRuble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bonus } from '..';
const PriceBox = (props) => {
    const { priceText, label, bonus, imgUrl } = props;
    return (_jsxs("div", { className: 'price-box ', children: [_jsxs("div", { children: [_jsx("div", { className: 'box-1', children: _jsx("img", { src: imgUrl, alt: '\u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435' }) }), _jsx("div", { className: 'box-2', children: label })] }), _jsxs("div", { children: [_jsxs("div", { className: `box-3${priceText === 'Бесплатно' ? ' green-price' : ''}`, children: [priceText, priceText !== 'Бесплатно' ? _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '0px', paddingTop: '0px' } }) : null] }), _jsx("div", { className: 'box-4', children: _jsx("div", { children: _jsx(Bonus, { bonus: bonus }) }) })] })] }));
};
export default inject('calculatePriceStore')(observer(PriceBox));
