import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useMemo, useCallback } from 'react';
import { iconSelector } from '../icons/iconSelector';
import { validateField } from './validation';
const PHONE_MASK = '**(***) ***-**-**';
const preparePhone = (phone) => {
    return phone === null || phone === void 0 ? void 0 : phone.split('').reduce((tempPhone, symbol) => {
        let index = tempPhone.length;
        let maskTemp = '';
        while (PHONE_MASK[index] !== '*') {
            maskTemp += PHONE_MASK[index];
            index++;
        }
        return tempPhone + maskTemp + symbol;
    }, '');
};
const handleChangePhone = (event) => {
    var _a;
    if (!((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value)) {
        return;
    }
    let phone = event.target.value;
    if (phone === '+') {
        // eslint-disable-next-line consistent-return
        return '+';
    }
    phone = phone[0] === '+'
        ? phone.slice(1).replace(/[^0-9]/g, '')
        : phone.replace(/[^0-9]/g, '');
    switch (phone) {
        case '':
            // eslint-disable-next-line consistent-return
            return '';
        case '7':
        case '8':
            // eslint-disable-next-line consistent-return
            return '+7';
        case '9':
            // eslint-disable-next-line consistent-return
            return '+79';
        default:
            if (phone.length === 1) {
                // eslint-disable-next-line consistent-return
                return `+79${phone}`;
            }
            if (phone[0] === '7' || phone[0] === '8') {
                phone = phone.slice(1);
            }
            if (phone[0] === '9') {
                phone = phone.slice(1);
            }
            // eslint-disable-next-line consistent-return
            return `+79${phone.slice(0, 9)}`;
    }
};
const PhoneInput = (props, ref) => {
    const { label, required, controlId, name, type, iconType, validationSchema, shouldValidate, className, error, disabled, autoFocus, } = props;
    const { control, getValues } = useFormContext();
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema) ? validateField(name, getValues(), validationSchema) : null);
    const icon = useMemo(() => iconSelector(iconType), [iconType]);
    const renderPhoneControl = useCallback(({ field }) => {
        if (icon) {
            return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: icon }), _jsx(InputGroup, { children: _jsx(Form.Control, Object.assign({}, field, { type: type !== null && type !== void 0 ? type : type, value: preparePhone(field.value), placeholder: label, inputMode: 'numeric', onChange: event => {
                                field.onChange(handleChangePhone(event));
                            }, className: `simple-edit ${errorLocal ? 'error-component error-border error-color-font' : ''}`, disabled: disabled, autoFocus: autoFocus, ref: ref })) })] }));
        }
        return (_jsx(InputGroup, { children: _jsx(Form.Control, Object.assign({}, field, { value: preparePhone(field.value), placeholder: label, onChange: event => {
                    field.onChange(handleChangePhone(event));
                }, className: `simple-edit ${errorLocal ? 'error-component error-border error-color-font' : ''}`, disabled: disabled, autoFocus: autoFocus })) }));
    }, [autoFocus, disabled, errorLocal, icon, label, type]);
    return (_jsxs(Form.Group, { controlId: controlId, className: `componentRow ${className}`, children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: renderPhoneControl }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: errorLocal !== null && errorLocal !== void 0 ? errorLocal : ' ' }) })] }));
};
export default inject('referenceStore')(observer(React.forwardRef(PhoneInput)));
