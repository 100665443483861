import { jsx as _jsx } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { MobileModalForm, } from '..';
const SimpleSelectMobileModal = (props) => {
    const { watch, setValue } = useFormContext();
    const values = watch();
    const { options, name, label, iconType, error, required, onClose, } = props;
    useEffect(() => {
    }, []);
    return (_jsx(MobileModalForm, { label: label, error: error, required: required, iconType: iconType, onClose: onClose, labelBeforeContent: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u0438\u0437 \u0441\u043F\u0438\u0441\u043A\u0430', children: _jsx(Row, { className: 'scrl', children: options.map(v => {
                return (_jsx(Col, { xs: 12, className: `rowSelector d-flex align-items-center${v.value === values[name] ? ' selected' : ''}`, onClick: () => {
                        setValue(name, v.value);
                        onClose();
                    }, children: v.label }, v.value));
            }) }) }));
};
export default SimpleSelectMobileModal;
