import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
// import InputGroup from 'react-bootstrap/InputGroup';
import { useMediaQuery } from '@react-hooks-library/core';
import InputGroup from 'react-bootstrap/InputGroup';
import { iconSelector } from '../icons/iconSelector';
// import { HookFormResponse } from '../../order/api/order';
import { validateField } from './validation';
const CODE_MASK = '** **';
const prepareCode = (code) => {
    return code === null || code === void 0 ? void 0 : code.split('').reduce((tempCode, symbol) => {
        let index = tempCode.length;
        let maskTemp = '';
        while (CODE_MASK[index] !== '*') {
            maskTemp += CODE_MASK[index];
            index++;
        }
        return tempCode + maskTemp + symbol;
    }, '');
};
const handleChangeCode = (event) => {
    var _a;
    if (!((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value)) {
        return;
    }
    let code = event.target.value;
    code = code.replace(/[^0-9]/g, '');
    // eslint-disable-next-line consistent-return
    return code.slice(0, 4);
};
const SmsInput = (props) => {
    const { label, required, controlId, name, iconType, validationSchema, shouldValidate, error, disabled, } = props;
    const { control, getValues } = useFormContext();
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema) ? validateField(name, getValues(), validationSchema) : null);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const inputGroupComponent = (field) => {
        return (_jsx(InputGroup, { children: _jsx(Form.Control, Object.assign({}, field, { placeholder: 'XX XX', inputMode: 'numeric', value: prepareCode(field.value), onChange: (e) => {
                    field.onChange(handleChangeCode(e));
                }, className: `simple-edit sms_line_input ${errorLocal ? 'error-component error-border error-color-font' : 'border-none'}`, alwaysShowMask: true, type: isMobile ? 'tel' : 'text', disabled: disabled, autoFocus: true })) })
        // <InputMask
        //   { ...field }
        //   mask='99 99'
        //   maskPlaceholder='X'
        //   inputMode='numeric'
        //   onChange={ (e) => {
        //     field.onChange(normalizePhone(e.target.value));
        //   } }
        //   className={ `simple-edit sms_line_input ${ errorLocal ? 'error-component error-border error-color-font' : 'border-none' }` }
        //   alwaysShowMask
        //   type={ isMobile ? 'tel' : 'text' }
        //   disabled={ disabled }
        //   autoFocus={ true }
        // />
        );
    };
    return (_jsxs(Form.Group, { controlId: controlId, className: 'componentRow', children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                    if (iconType) {
                        return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                    }
                    return inputGroupComponent(field);
                } }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: errorLocal !== null && errorLocal !== void 0 ? errorLocal : ' ' }) })] }));
};
export default inject('referenceStore')(observer(SmsInput));
