import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import cc from 'classcat';
import Button from 'react-bootstrap/Button';
import ym from 'react-yandex-metrika';
import { stateAppActions } from '../../../lib/mobx/stateAppStore';
const Footer = (props) => {
    const { nextNumber, label, validation, disabled, onClickShowOffice, disabledShowOffice, onClick, variant, shadow = false, } = props;
    return (_jsxs("div", { className: cc(['widget-footer', 'padding_main_layer', { shadow }]), children: [onClickShowOffice
                && (_jsx(Row, { style: { marginTop: '10px' }, children: _jsx(Col, { children: _jsx(Button, { className: 'next-button show-on-map', variant: 'outline-primary', onClick: onClickShowOffice, disabled: disabledShowOffice, children: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u043D\u0430 \u043A\u0430\u0440\u0442\u0435" }) }) })), _jsx(Row, { children: _jsx(Col, { children: _jsx(Button, { className: 'next-button', variant: variant !== null && variant !== void 0 ? variant : 'outline-primary', onClick: () => {
                            if (((validation && validation()) || (!validation)) && nextNumber) {
                                if (nextNumber === 1) {
                                    ym('reachGoal', 'choose_service_send');
                                }
                                if (nextNumber === 2) {
                                    ym('reachGoal', 'choose_parametres_send');
                                }
                                if (nextNumber === 3) {
                                    ym('reachGoal', 'choose_office_send');
                                }
                                stateAppActions.setAppStateOrder(nextNumber);
                            }
                            if (onClick) {
                                onClick();
                            }
                        }, disabled: disabled, children: label }) }) })] }));
};
export default Footer;
