var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction } from 'mobx';
import orderController from './controller';
export const authStore = observable({
    sendSmsRequest: null,
    confirmSmsRequest: null,
    confirmSmsResponse: null,
    error: null,
});
export const authActions = {
    sendSms: (request) => __awaiter(void 0, void 0, void 0, function* () {
        runInAction(() => {
            authStore.sendSmsRequest = request;
        });
        const response = yield orderController.sendSms(request);
        if (response.errorData) {
            authStore.sendSmsRequest = null;
        }
        authActions.handleError(response);
        return response.data;
    }),
    confirmSms: (request) => __awaiter(void 0, void 0, void 0, function* () {
        runInAction(() => {
            authStore.confirmSmsRequest = request;
        });
        const response = yield orderController.confirmSms(request);
        if (response.errorData) {
            authStore.confirmSmsRequest = null;
            return response.errorData;
        }
        authActions.handleError(response);
        if (!response.errorData) {
            authStore.confirmSmsResponse = response.data;
        }
        return response.data;
    }),
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            authStore.error = errorData;
            throw errorData;
        }
    }),
    clearError: action(() => {
        authStore.error = null;
    }),
};
