// import { officeActions } from '../../map/store/officeStore';
export const paramsTireStorageObj = (values) => {
    var _a, _b;
    return {
        diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
        partnerOfficeId: values === null || values === void 0 ? void 0 : values.partnerOfficeId,
        tireCount: values === null || values === void 0 ? void 0 : values.tireCountStorage,
        storageDurationInMonths: (_a = values === null || values === void 0 ? void 0 : values.storageDurationInMonths) !== null && _a !== void 0 ? _a : 6,
        storedObjectRefValueId: (_b = values === null || values === void 0 ? void 0 : values.storedObjectRefValueId) !== null && _b !== void 0 ? _b : 379,
    };
};
export const paramsTireFittingObj = (values) => {
    return {
        diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
        partnerOfficeId: values === null || values === void 0 ? void 0 : values.partnerOfficeId,
        tireCount: values === null || values === void 0 ? void 0 : values.tireCount,
        tireOperationRefValueId: typeof (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) === 'string'
            ? parseInt(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId, 10)
            : values === null || values === void 0 ? void 0 : values.tireOperationRefValueId,
        tireRunflat: (values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'yes',
        vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
        wheelBalancing: false,
    };
};
export const bonusTireFitting = (office) => {
    var _a, _b, _c;
    return (((_c = (_a = office === null || office === void 0 ? void 0 : office.bonusReceivingPercent) !== null && _a !== void 0 ? _a : (_b = office === null || office === void 0 ? void 0 : office.bonusReceivingPercentByService) === null || _b === void 0 ? void 0 : _b.stationTireFitting) !== null && _c !== void 0 ? _c : 0) / 100);
};
export const bonusTireStorage = (office) => {
    var _a, _b;
    return (((_b = (_a = office === null || office === void 0 ? void 0 : office.bonusReceivingPercentByService) === null || _a === void 0 ? void 0 : _a.stationTireStorage) !== null && _b !== void 0 ? _b : 0) / 100);
};
export const bonusPercentByType = (typePrice, office) => {
    if (typePrice === 0) {
        return bonusTireFitting(office);
    }
    if (typePrice === 1) {
        return bonusTireStorage(office);
    }
    return 0;
};
// согласно требованиям бизнеса задаём параметры:
// https://avtoapp.evateam.ru/project/Document/DOC-000545#raschet-czeny-ot
const tireFittings = [
    { tireOperationRefValueId: 244, tireCount: 4, label: 'Шиномонтаж' },
    { tireOperationRefValueId: 361, tireCount: 4, label: 'Шиномонтаж' },
    { tireOperationRefValueId: 487, tireCount: 4, label: 'Шиномонтаж' },
    { tireOperationRefValueId: 245, tireCount: 4, label: 'Балансировка' },
    { tireOperationRefValueId: 243, tireCount: 1, label: 'Ремонт шин' },
    { tireOperationRefValueId: 363, tireCount: 1, label: 'Дошиповка' },
];
export const tireFittingRename = (tireOperationRefValueId) => {
    return (tireFittings
        // eslint-disable-next-line eqeqeq
        .find((v) => v.tireOperationRefValueId == tireOperationRefValueId));
};
