import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import { useMediaQuery } from '@react-hooks-library/core';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import SuccessfulSuccessContent from './SuccessfulSuccessContent';
import { CloseIcon } from '../core';
const SuccessfulSuccessModalPanel = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleClose = () => { stateAppActions.setAppStateOrder(4); };
    const handleClick = () => {
        stateAppActions.setAppStateOrder(4);
    };
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    _jsx(_Fragment, { children: isMobile ? (_jsxs(_Fragment, { children: [_jsx("div", { className: 'close_icon_wo_header', children: _jsx(CloseIcon, { onClick: handleClick }) }), _jsx(SuccessfulSuccessContent, {})] })) : (_jsx(Modal, { show: true, onHide: handleClose, backdrop: 'static', children: _jsx(SuccessfulSuccessContent, {}) })) }));
};
export default SuccessfulSuccessModalPanel;
