var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { referenceActions } from '../../order/store/referenceStore';
import { Select } from '..';
const RefValueSelector = (props) => {
    const { refId, label, error, required, name, placeholder, } = props;
    const [localRef, setLocalRef] = useState(null);
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!localRef) {
            const ref = yield referenceActions.getRef(refId); // ?  referenceStore?.ref56 ?? [];
            setLocalRef(ref);
        }
    });
    useEffect(() => {
        loadData();
    }, []);
    const options = (localRef !== null && localRef !== void 0 ? localRef : []).map(v => { return { label: v.name, value: v.id }; });
    return (_jsx(Select, { label: label, error: error, required: required, options: options, name: name, placeholder: placeholder }));
};
export default inject('referenceStore')(observer(RefValueSelector));
