import * as Yup from 'yup';
// import { HookFormResponse } from './api/order';
// TODO - доделать типизацию для ObjectSchema и убрать any в validationSchema
/* export interface ClientRegistrationInterface
{
        firstName: string,
        lastName: string,
        phoneNumber: string,
}

const fillingClientData: Yup.ObjectSchema<ClientRegistrationInterface> = Yup.object({
  firstName: Yup.string().required('Укажите имя'),
  lastName: Yup.string().required('Укажите фамилию'),
  // phoneNumber: Yup.string().required('Укажите телефон').min(11, 'Минимум 10 цифр'),
}); */
export const fillingClientData = Yup.object().shape({
    /* firstName: Yup.string()
      .required('Укажите имя')
      .min(2, 'Слишком коротко')
      .matches(/^[а-яА-ЯёЁ-\s]+$/, 'Только русские буквы'),
    lastName: Yup.string()
      .required('Укажите фамилию')
      .min(2, 'Слишком коротко')
      .matches(/^[а-яА-ЯёЁ-\s]+$/, 'Только русские буквы'),
    phoneNumber: Yup.string()
      .required('Укажите телефон')
      .min(12, 'Короткий телефон')
      .test({
        name: 'phoneNumber',
        exclusive: true,
        message: 'Некорректный номер',
        test: (value) => value.at(2) === '9',
      }), */
    date: Yup.date()
        .typeError('Выберите дату')
        .nullable()
        .required('Выберите дату'),
    time: Yup.string().required('Выберите время'),
});
export const fillingClientData5koleso = Yup.object().shape({
    firstName: Yup.string()
        .required('Укажите имя')
        .min(2, 'Слишком коротко')
        .matches(/^[а-яА-ЯёЁ-\s]+$/, 'Только русские буквы'),
    vehicleNumber: Yup.string().when('isForeign', ([isForeign], schema) => {
        return isForeign
            ? schema.required('Укажите гос.номер').min(2, 'Слишком короткий')
            : schema
                .required('Укажите гос.номер')
                .min(8, 'Слишком короткий')
                .max(20, 'Максимальная длина 20 символов')
                .matches(/^[АВЕКМНОРСТУХ]{1,2}\s?\d{3,4}\s?[АВЕКМНОРСТУХ]{0,2}\s?\d{2,3}$/i, 'Некорректный гос.номер');
    }),
    phoneNumber: Yup.string()
        .required('Укажите телефон')
        .min(12, 'Короткий телефон')
        .test({
        name: 'phoneNumber',
        exclusive: true,
        message: 'Некорректный номер',
        test: (value) => value.at(2) === '9',
    }),
    date: Yup.date()
        .typeError('Выберите дату')
        .nullable()
        .required('Выберите дату'),
    time: Yup.string().required('Выберите время'),
});
