import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { OfficeView } from '..';
const OfficeContextView = (props) => {
    const { showBorder } = props;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const { office } = values;
    return (_jsx(OfficeView, { office: office, showBorder: showBorder }));
};
export default OfficeContextView;
