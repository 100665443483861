import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
// get our fontawesome imports
import { faRuble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import isEqual from 'lodash/isEqual';
// import { string } from 'prop-types';
import { Bonus } from '..';
import { 
// paramsTireStorageObj, paramsTireFittingObj,
bonusTireFitting, bonusTireStorage, tireFittingRename, } from './PriceFunc';
// import { stateAppActions } from '../../lib/mobx/stateAppStore';
/* import {
  PriceResponseInterface,
  TireFittingPriceRequestInterface,
  TireStoragePriceRequestInterface,
} from '../../order/api/calculatePrice'; */
/*export interface PriceV2ComponentProps {
  calculatePriceStore?: CalculatePriceStore,
} */
const PriceV2Component = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    // const { calculatePriceStore } = props;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    /*   const {
      tireFittingPriceResponse,
      tireStoragePriceResponse,
    } = calculatePriceStore; */
    /* const [tireFittingPriceResponse, setTireFittingPriceResponse] = useState<PriceResponseInterface>(null);
    const [tireFittingPriceRequest, setTireFittingPriceRequest] = useState<TireFittingPriceRequestInterface>(null);
  
    const [tireStoragePriceResponse, setTireStoragePriceResponse] = useState<PriceResponseInterface>(null);
    const [tireStoragePriceRequest, setTireStoragePriceRequest] = useState<TireStoragePriceRequestInterface>(null);
  
    const paramsTireFitting = paramsTireFittingObj(values);
    const paramsTireStorage = paramsTireStorageObj(values); */
    /* const loadData = async () => {
      // if (!values?.partnerOfficeId && !priceFrom) return;
      const isNoEqualTireFittingRequest = !tireFittingPriceRequest || !isEqual(
        paramsTireFitting,
        tireFittingPriceRequest
      );
  
      if (isNoEqualTireFittingRequest && values?.tireOperationRefValueId) {
        calculatePriceActions.getTireFittingPrice(
          paramsTireFitting
        ).then((res) => {
          setTireFittingPriceResponse(res);
          setTireFittingPriceRequest(paramsTireFitting);
        });
      }
  
      const isNoEqualTireStorageRequest = !tireStoragePriceRequest || !isEqual(
        paramsTireStorage,
        tireStoragePriceRequest
      );
  
      if (isNoEqualTireStorageRequest) {
        calculatePriceActions.getTireStoragePrice(
          paramsTireStorage
        ).then((res) => {
          setTireStoragePriceResponse(res);
          setTireStoragePriceRequest(paramsTireStorage);
        });
      }
    };
  
    useEffect(() => {
      loadData();
    }, []); */
    // const storageKoef: number = values?.hasTireStorage ? 1 : 0;
    // const tireOperationKoef: number = values?.tireOperationRefValueId ? 1 : 0;
    const totalCost = ((_b = (_a = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _a === void 0 ? void 0 : _a.price) !== null && _b !== void 0 ? _b : 0) + ((_d = (_c = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _c === void 0 ? void 0 : _c.price) !== null && _d !== void 0 ? _d : 0);
    const totalBonus = ((_f = (_e = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _e === void 0 ? void 0 : _e.price) !== null && _f !== void 0 ? _f : 0) * bonusTireFitting(values === null || values === void 0 ? void 0 : values.office)
        + ((_h = (_g = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _g === void 0 ? void 0 : _g.price) !== null && _h !== void 0 ? _h : 0) * bonusTireStorage(values === null || values === void 0 ? void 0 : values.office);
    const setFreePrice = (cost) => {
        return (cost !== null && cost !== void 0 ? cost : 0) > 0 ? cost.toString() : 'Бесплатно';
    };
    const setFaRuble = (cost) => {
        return (cost !== null && cost !== void 0 ? cost : 0) > 0 ? _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '5px' } }) : null;
    };
    const tireFitting = tireFittingRename(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId);
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { className: 'priceV2Component', children: [_jsxs("div", { className: 'rowPrice', children: [_jsx("div", { className: 'avtoapp_color_font_16', children: " \u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u0443\u0441\u043B\u0443\u0433" }), _jsxs("div", { className: 'avtoapp_color_font_22_700', children: [setFreePrice(totalCost), setFaRuble(totalCost)] })] }), (values === null || values === void 0 ? void 0 : values.clientPromo68)
                        ? (_jsxs("div", { className: 'rowPrice font-16-400-gray-dark', children: [_jsxs("div", { children: [" ", (_j = tireFitting === null || tireFitting === void 0 ? void 0 : tireFitting.label) !== null && _j !== void 0 ? _j : 'Шиномонтаж', "  "] }), _jsxs("div", { children: [setFreePrice((_k = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _k === void 0 ? void 0 : _k.price), setFaRuble((_l = values === null || values === void 0 ? void 0 : values.clientPromo68) === null || _l === void 0 ? void 0 : _l.price)] })] })) : null, (values === null || values === void 0 ? void 0 : values.clientPromo69)
                        ? (_jsxs("div", { className: 'rowPrice font-16-400-gray-dark', children: [_jsx("div", { children: " \u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D " }), _jsxs("div", { children: [setFreePrice((_m = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _m === void 0 ? void 0 : _m.price), setFaRuble((_o = values === null || values === void 0 ? void 0 : values.clientPromo69) === null || _o === void 0 ? void 0 : _o.price)] })] })) : null, _jsxs("div", { className: 'rowPrice font-16-400-gray-dark', children: [_jsx("div", { children: " \u0412\u044B \u043D\u0430\u043A\u0430\u043F\u043B\u0438\u0432\u0430\u0435\u0442\u0435 " }), _jsx("div", { children: _jsx(Bonus, { bonus: Math.ceil(totalBonus) }) })] })] }), ((values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'dontknow')
                && (_jsx(Row, { className: 'pt-1', children: _jsx(Col, { children: _jsx("div", { className: 'label pb-1 pt-1', children: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C \u0443\u0432\u0435\u043B\u0438\u0447\u0435\u043D\u0430 \u043D\u0430 20%, \u0435\u0441\u043B\u0438 \u0443 \u0432\u0430\u0441 \u0440\u0435\u0437\u0438\u043D\u0430 RunFlat. \u0423\u0442\u043E\u0447\u043D\u044F\u0439\u0442\u0435 \u043F\u043E\u0434\u0440\u043E\u0431\u043D\u043E\u0441\u0442\u0438 \u0443 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u043E\u0432." }) }) }))] }));
};
export default inject('calculatePriceStore')(observer(PriceV2Component));
