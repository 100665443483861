import { makeRequest } from '../../../lib/api';
const OPEN_API_PATH = '/v1/open';
class officeController {
    constructor() {
        // Вернуть список офисов для стартовой страницы
        Object.defineProperty(this, "getOffices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${OPEN_API_PATH}/station_tire_fitting/offices/simplified`, request);
            }
        });
        // Вернуть список офисов для стартовой страницы
        Object.defineProperty(this, "getOfficesByParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${OPEN_API_PATH}/station_tire_fitting/offices`, request);
            }
        });
    }
}
export default new officeController();
