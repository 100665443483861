import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StarFull, StarHalf } from '..';
// export interface OfficeContextViewProps {
// rating?:number,
// }
const OfficeRating = () => {
    // const { watch, setValue } = useFormContext(); // retrieve all hook methods
    // const values = watch();
    // -const { office } = values;
    return (_jsxs("div", { className: 'rating', children: [_jsx(StarFull, {}), _jsx(StarFull, {}), _jsx(StarFull, {}), _jsx(StarFull, {}), _jsx(StarHalf, {})] }));
};
export default OfficeRating;
