var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction } from 'mobx';
import sortBy from 'lodash/sortBy';
import orderController from './controller';
export const referenceStore = observable({
    ref56: [],
    refs: [],
    error: null,
});
export const referenceActions = {
    getRef56: () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const refs = (_a = referenceStore.refs) !== null && _a !== void 0 ? _a : [];
        const ref = (_b = refs.find(v => v.refId === 56)) === null || _b === void 0 ? void 0 : _b.ref;
        if (ref) {
            runInAction(() => {
                referenceStore.ref56 = ref.filter(v => !v.deletedDate);
            });
            return;
        }
        referenceActions.clearError();
        const response = yield orderController.getUniversalReference(56);
        referenceActions.handleError(response);
        runInAction(() => {
            var _a;
            const sortedRef56 = (_a = response.data) === null || _a === void 0 ? void 0 : _a.sort((a, b) => { var _a, _b; return ((_a = a.sort) !== null && _a !== void 0 ? _a : 0) - ((_b = b.sort) !== null && _b !== void 0 ? _b : 0); });
            referenceStore.ref56 = [...sortedRef56].filter(v => !v.deletedDate);
            referenceStore.refs = [...referenceStore.refs, { ref: sortedRef56, refId: 56 }];
        });
    }),
    getRef: (refId) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f;
        const sortFunc = (arr) => {
            return sortBy(arr, (e) => {
                var _a;
                return (_a = e.sort) !== null && _a !== void 0 ? _a : 0;
            });
        };
        const refs = (_c = referenceStore.refs) !== null && _c !== void 0 ? _c : [];
        const ref = (_d = refs.find(v => v.refId === refId)) === null || _d === void 0 ? void 0 : _d.ref;
        if (ref) {
            return ref;
        }
        const response = yield orderController.getUniversalReference(refId);
        referenceActions.handleError(response);
        if (!response.errorData) {
            referenceStore.refs = [...referenceStore.refs, { ref: sortFunc((_e = response.data) !== null && _e !== void 0 ? _e : []), refId }].sort();
        }
        return sortFunc((_f = response.data) !== null && _f !== void 0 ? _f : []);
    }),
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            referenceStore.error = errorData;
            throw errorData;
        }
    }),
    clearUR: action(() => {
        referenceStore.ref56 = [];
    }),
    clearError: action(() => {
        referenceStore.error = null;
    }),
};
