import { jsx as _jsx } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import PriceBox from './PriceBox';
// import { officeActions } from '../../map/store/officeStore';
import { 
// paramsTireStorageObj,
// paramsTireFittingObj,
bonusPercentByType, } from './PriceFunc';
const PriceComponent = (props) => {
    const { typePrice, office, 
    /* priceFrom,
    priceFromTireStoragePriceRequestInterface,
    priceFromTireFittingPriceRequestInterface, */
    label, } = props;
    /* const {
      tireFittingPriceResponse,
      tireStoragePriceResponse,
      tireFittingPriceRequest,
      tireStoragePriceRequest,
    } = calculatePriceStore; */
    const { getValues } = useFormContext(); // retrieve all hook methods
    const values = getValues();
    // const [tireFittingPriceResponse, setTireFittingPriceResponse] = useState<PriceResponseInterface>(null);
    // const [tireFittingPriceRequest, setTireFittingPriceRequest] = useState<TireFittingPriceRequestInterface>(null);
    // const [tireStoragePriceResponse, setTireStoragePriceResponse] = useState<PriceResponseInterface>(null);
    // const [tireStoragePriceRequest, setTireStoragePriceRequest] = useState<TireStoragePriceRequestInterface>(null);
    /* const paramsTireFitting = priceFrom
      ? priceFromTireFittingPriceRequestInterface
      : paramsTireFittingObj(values);
  
    const paramsTireStorage = priceFrom
      ? priceFromTireStoragePriceRequestInterface
      : paramsTireStorageObj(values); */
    const clientPromo = typePrice === 0 ? values === null || values === void 0 ? void 0 : values.clientPromo68 : values === null || values === void 0 ? void 0 : values.clientPromo69;
    /* const loadData = async () => {
      if (!values?.partnerOfficeId && !priceFrom) return;
      if (typePrice === 0) {
        const isNoEqualTireFittingRequest = !tireFittingPriceRequest || !isEqual(
          paramsTireFitting,
          toJS(calculatePriceStore?.tireFittingPriceRequest)
        );
  
        if (isNoEqualTireFittingRequest) {
          await calculatePriceActions.getTireFittingPrice(
            paramsTireFitting
          ).then((res) => {
            // setTireFittingPriceResponse(res);
            // setTireFittingPriceRequest(paramsTireFitting);
          });
        }
      }
      if (typePrice === 1) {
        const isNoEqualTireStorageRequest = !tireStoragePriceRequest || !isEqual(
          paramsTireStorage,
          tireStoragePriceRequest
        );
  
        if (isNoEqualTireStorageRequest) {
          await calculatePriceActions.getTireStoragePrice(
            paramsTireStorage
          ).then((res) => {
            // setTireStoragePriceResponse(res);
            // setTireStoragePriceRequest(paramsTireStorage);
          });
        }
      }
    };
  
    useEffect(() => {
      if (((typePrice === 0) && (values?.partnerOfficeId !== tireFittingPriceRequest?.partnerOfficeId))
          || ((typePrice === 1) && (values?.partnerOfficeId !== tireFittingPriceRequest?.partnerOfficeId))) {
        loadData();
      }
    }, [values?.partnerOfficeId, values?.tireOperationRefValueId]);
  
    useEffect(() => {
      loadData();
    }, []); */
    const designItems = [
        {
            label: 'Шиномонтаж',
            img: 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/tirefitting.png',
        },
        {
            label: 'Хранение',
            img: 'https://storage.yandexcloud.net/avtoapp.bucket.prod.data.public/applications/pictures/icons/storage.png',
        },
    ];
    const localPrice = clientPromo === null || clientPromo === void 0 ? void 0 : clientPromo.price;
    /* const localPriceExtended:string = (priceFrom || (typePrice === 0 && !values?.isExactPrice))
      ? (`от ${ localPrice?.toString() }`)
      : localPrice?.toString(); */
    const localPriceExtended = localPrice ? localPrice === null || localPrice === void 0 ? void 0 : localPrice.toString() : 'Бесплатно';
    /* const bonusPercent = ():number => {
      if (typePrice === 0) {
        return (office.bonusReceivingPercent ?? office.bonusReceivingPercentByService?.stationTireFitting ?? 0) / 100;
      }
      if (typePrice === 1) {
        return (office.bonusReceivingPercentByService?.stationTireStorage
          ?? officeActions.getPercentBonusForStorage(office?.id) // по временной схеме достаю % бонусов из officesGlobal
          ?? 0) / 100;
      }
      return 0;
    }; */
    // if (localPrice) {
    return (_jsx(PriceBox, { imgUrl: designItems[typePrice].img, label: label !== null && label !== void 0 ? label : designItems[typePrice].label, priceText: localPriceExtended || '--', bonus: Math.ceil((localPrice !== null && localPrice !== void 0 ? localPrice : 0) * bonusPercentByType(typePrice, office)) }));
    // }
    // return (null);
};
export default inject('calculatePriceStore')(observer(PriceComponent));
